import axios from '@/common/js/request'

export function archive_save(data){
    const archive_save = axios({
        url: '/archive/save',
        method: 'post',
        data:data
    })
    return archive_save
}

export function archive_page(data){
    const archive_page = axios({
        url: '/archive/page',
        method: 'get',
        params:data
    })
    return archive_page
}

export function archive_delete(data){
    const archive_delete = axios({
        url: '/archive/delete',
        method: 'get',
        params:data
    })
    return archive_delete
}

export function archive_pageArchived(data){
    const archive_pageArchived = axios({
        url: '/archive/pageArchived',
        method: 'get',
        params:data
    })
    return archive_pageArchived
}
export function archive_pageArchivedRecord(data){
    const archive_pageArchivedRecord = axios({
        url: '/archive/pageArchivedRecord',
        method: 'get',
        params:data
    })
    return archive_pageArchivedRecord
}