<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
        <div class="tab">
        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left:20px">
          <el-tab-pane label="待归档" name="first"></el-tab-pane>
          <el-tab-pane label="归档记录" name="seco"></el-tab-pane>
          </el-tabs>
        <div class="SearchBar"  v-if="activeName!='first'">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="档案号/合同编号" class="lab">
              <el-input
                v-model="search.code"
                placeholder="请输入档案号/合同编号"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="归档日期" class="lab">
               <el-date-picker
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <span >
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
            </span>
            
          </el-form>
        </div>
        <div>
           
          <!--数据表格-->
          
          <el-table v-if="activeName!='first'"
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            height="calc(100vh - 375px)"
            :stripe="true"
           
            @sort-change="sort_change"
            
          >
          
           <el-table-column 
                type="index"
                label="序号"
                width="70" align="center">
                </el-table-column>
                <el-table-column 
              prop="archivesCode" 
              label="档案号"
              align="center"
              show-overflow-tooltip  
            > </el-table-column>
            <el-table-column
              prop="contractCode"
              label="合同编号"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="teamName"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
               
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="合同类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="签订日期"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="annexUrl"
              label="合同附件"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <a :href="row.annexUrl"  target="_back">查看</a>
              </template>
            </el-table-column>

            <el-table-column
              prop="statusName" 
              label="合同状态"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createBy" 
              label="归档人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime" 
              label="归档日期"
              align="center"
              sortable="custom"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left">
              <template slot-scope="{ row }">
                   <el-button 
                    type="primary" v-if="activeName=='first'"
                    size="small" @click="showadd(row)"
                    >归档</el-button>
                    <el-button 
                    type="danger" v-if="activeName=='seco'&&userName=='超级管理员'"
            
                    size="small" @click="editStatus(row,1)"
                    >删除</el-button>
                    
              </template>
            </el-table-column>
          </el-table>
             
          <el-table v-if="activeName=='first'"
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            height="calc(100vh - 308px)"
          >
          
           <el-table-column 
                type="index"
                label="序号"
                width="70" align="center">
                </el-table-column>
                
            <el-table-column
              prop="contractCode"
              label="合同编号"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="teamName"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
               
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="合同类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="签订日期"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="annexUrl"
              label="合同附件"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <a :href="row.annexUrl" download="" target="_back">查看</a>
              </template>
            </el-table-column>
            
            <el-table-column
              prop="createBy" 
              label="创建人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime" 
              label="创建时间"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
           
            <el-table-column prop="operation" label="操作" align="left">
              <template slot-scope="{ row }">
                   <el-button 
                    type="primary" v-if="activeName=='first'"
                    size="small" @click="showadd(row)"
                    >归档</el-button>
                    <el-button 
                    type="danger" v-if="activeName=='seco'&&userName=='超级管理员'"
                   
                    size="small" @click="editStatus(row,1)"
                    >删除</el-button>
                    
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="合同归档"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form ref="addform" :model="form" label-width="100px" :rules="rule" size="small">
        <div>
          <!-- <el-form-item label="合同编号" prop="contractCode">
            <el-input type="text" v-model="form.contractCode"  maxlength="100" name="limitLength"></el-input>
          </el-form-item> -->
         <el-form-item label="档案号" prop="archivesCode">
            <el-input type="text" v-model="form.archivesCode"  maxlength="100"  name="limitLength"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
  
</template>
<script>
import {archive_page,archive_save,archive_delete,archive_pageArchived,archive_pageArchivedRecord } from "../../RequestPort/apply/conFile.js"

export default {
  components: {},
  name: 'applyList',
  mixins: [],
  data() {
    return {
      search: { current: 1, size: 100,}, //搜索
      searchDatatime:[],
      total: 10,
      tableData: [], //列表对象
      dialogVisible:false,
      form:{},
      rule:{
         contractCode:{ required: true, message: "请输入合同编号", trigger: "blur" },
         archivesCode:{ required: true, message: "请输入档案号", trigger: "blur" }
      },
      activeName:'first',
      username:''
      
    }
  },
  mounted() {
    this.userName = localStorage.getItem("userName");
    this.getList();
  },
  methods: {
     sort_change(column){
      this.search.sortColumn=column.prop;
      if(column.order=='ascending'){
        this.search.sortKey =0
      }else{
        this.search.sortKey =1
      }
      this.getList(1)
    },
    handleClick(){
        this.reset()
    },
    getList(current) {
      
      if (current) {
        this.search.current = 1
      }
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      console.log(this.activeName);
      //查列表
      if(this.activeName=='first'){
        archive_pageArchived(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
       })
      }else{
        archive_pageArchivedRecord(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
       })
      }
      
    },
   
    //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
      }),
      this.searchDatatime=[];
        this.getList()
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    showadd(row){
      this.dialogVisible = true;
      this.form.contractId = row.id;
    },
    clearnF() {
      this.form = {
        contractCode:'',
        archivesCode: '',
      }
    },
    saveadd(){
      this.$refs['addform'].validate(valid => {
        if (valid) {
          
            archive_save(this.form).then(res=>{
              if(res.code=='200'){
                this.$message.success('添加成功');
                this.clearnF()
                this.getList(1);
                this.dialogVisible=false;

              }
           })
        }
      })
    },
    addUser(row){
        apply_findById({id:row.id}).then(res=>{
          if(res.code==200){
            this.form = res.data;
            this.form.urlList
            this.dialogVisible = true;
          }
        })

        
    },
    editStatus(row,falg){
          var msg = '删除合同归档'
          this.$confirm('确定要'+msg+'吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          let params = {
            id: row.id
          }
          archive_delete(params).then(res => {
            if (res.code == 200) {
              this.$message.success(msg+'成功')
            }
            this.getList()
          })
        })
    }
  }
}
</script>

<style  lang="less" scoped>
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin-right: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.tab{background-color: #fff;border-radius: 6px;margin: 10px;}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
   padding-top: 0px;
    margin-top: 0px;
}

.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 2%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
